<template>
  <section class="flex justify-center mt-40">
    <h1 class="text-blue-violet-crayola text-9xl font-black">Coming Soon!</h1>
  </section>
</template>

<script>
export default {
  name: "ComingSoon"
}
</script>

<style scoped>

</style>